import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Skin from './Skin';
import {
  getUserUpdateSkinState,
  actions as addSkinActions,
} from '../../../ducks/user/addSkin';
import {
  getUserEquipSkinState,
  actions as equipSkinActions,
} from '../../../ducks/user/equipSkin';
import { getAuthUserId } from '../../../utilities/authUtils';
import SkinsSkeleton from '../../../components/Skeleton/SkinsSkeleton';
import Grid from '../../../components/Grid/Grid';
import GridItem from '../../../components/Grid/GridItem';
import ContentBlock from '../../../components/ContentBlock';
import './SkinSection.css';

const SkinSection = ({
  skins,
  skinsOwned,
  userTroutcoins,
  skinEquipped,
  isLoadingSkins,
}) => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const userId = getAuthUserId(user);
  const handleSkinButtonClick = (skinId, skinPrice) => {
    // buy skin if user doesnt have it and also can afford it
    if (!skinsOwned?.includes(skinId) && userTroutcoins >= skinPrice) {
      dispatch(
        addSkinActions.load(
          { id: userId, skinId, skinPrice },
          getAccessTokenSilently
        )
      );
    } else if (!skinsOwned?.includes(skinId) && userTroutcoins < skinPrice) {
      // dispatch error message you can't afford that LOL
    } else if (skinsOwned?.includes(skinId)) {
      // lets do ASSIGN_SKIN_TO_USER setUserSkin
      dispatch(
        equipSkinActions.load({ id: userId, skinId }, getAccessTokenSilently)
      );
    }
  };
  const isAddingSkin = useSelector(
    (state) => getUserUpdateSkinState(state)?.isLoading
  );
  const isEquippingSkin = useSelector(
    (state) => getUserEquipSkinState(state)?.isLoading
  );
  if (!skins) {
    return null;
  }
  const doesOwnSkin = (skin) => skinsOwned.includes(skin.id);
  const canBuySkin = (skin) => userTroutcoins >= skin.price;
  const isLoadingSkin = isAddingSkin || isEquippingSkin;
  const skinsList = [...skins].sort((a, b) => a.price - b.price);

  return (
    <section className="skins skin-section" id="skins">
      <ContentBlock className="margin-bottom">
        <h3>Skins</h3>
        <p>Equip or buy new skins for your in-game RNG Royale character.</p>
      </ContentBlock>
      {isLoadingSkins && <SkinsSkeleton message="Retrieving skins..." />}
      <div className="skins__grid-container">
        <Grid>
          {skinsList.map((skin) => (
            <GridItem key={skin.id}>
              <Skin
                key={skin.id}
                skin={skin}
                doesOwnSkin={doesOwnSkin(skin)}
                canBuySkin={canBuySkin(skin)}
                onButtonClick={() => handleSkinButtonClick(skin.id, skin.price)}
                isLoading={isLoadingSkin}
                isEquipped={skinEquipped === skin.id}
              />
            </GridItem>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default SkinSection;
