import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSkinsListState,
  actions as listSkinsActions,
} from '../../ducks/skins/list';
import { actions as modalActions } from '../../ducks/modal';
import SkinSection from './SkinSection/SkinSection';
import CommerceContainer from '../../components/CommerceContainer';
import ContentBlock from '../../components/ContentBlock';
import StreamerInfo from './StreamerInfo';
import TroutCoin3d from '../../components/TroutCoin3d';

/**
 * Inner profile details for current user
 * @param userData {
 * troutCoins: number;
 * royaleWins: number;
 * royalePoints: number;
 * skinsOwned: string[];
 * skinEquipped: string;
 * }
 * @returns user details
 */
const ProfileDetails = ({ userData, isLoadingUser }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const skins = useSelector((state) => getSkinsListState(state).data);
  useEffect(() => {
    if (!skins) {
      dispatch(listSkinsActions.load({}, getAccessTokenSilently));
    }
  }, [dispatch, getAccessTokenSilently, skins]);
  const isLoadingSkins = useSelector(
    (state) => getSkinsListState(state)?.isLoading
  );
  const errorLoadingSkinsMessage = useSelector(
    (state) => getSkinsListState(state)?.errorMessage
  );
  const currentSkinWithData = skins?.find(
    (skin) => skin.id === userData?.skinEquipped
  );
  const currentSkinImgSrc = currentSkinWithData?.imageUrl;
  const currentSkinName = currentSkinWithData?.displayName;
  return (
    <div
      className={`profile-details ${
        isLoadingUser || isLoadingSkins ? 'profile-details--loading' : ''
      }`}
    >
      <div className="profile-details__inner">
        <div className="profile__skin-equipped">
          <ContentBlock
            className="margin-bottom"
            isLoading={isLoadingUser || isLoadingSkins}
            loaderSize={{ width: '300px', height: '300px' }}
          >
            <div className="profile__skin-equipped-pic">
              {isLoadingSkins && <em>Fetchin skin data...</em>}
              {errorLoadingSkinsMessage && <em>Error fetching skin data...</em>}
              {!isLoadingSkins && (
                <img
                  className="skin-equipped-pic__image"
                  src={currentSkinImgSrc}
                  alt="current skin equipped"
                />
              )}
            </div>
            <div>
              <em>Skin equipped</em>: {currentSkinName}
            </div>
          </ContentBlock>
        </div>
        <div className="profile__stats">
          <ContentBlock
            className="margin-bottom"
            isLoading={isLoadingUser || isLoadingSkins}
          >
            <div className="profile__stats__card">
              <div
                className="profile__coin-container"
                title={`You have ${userData?.troutCoins || 0} Trout coins`}
              >
                <div className="profile__coin-container__coin">
                  <TroutCoin3d />
                </div>
                <span className="profile__coin-text">
                  <strong className="profile__coin-text screenreader-only">
                    Trout coins:{' '}
                  </strong>
                  <span className="profile__coin-coin">
                    {userData?.troutCoins || 0}
                  </span>
                </span>
              </div>
              <div className="profile__stat">
                <span className="profile__stat__number">
                  {userData?.royaleWins || 0}
                </span>
                <span> Royale Wins</span>
              </div>
              <div className="profile__stat">
                <span className="profile__stat__number">
                  {userData?.royalePoints || 0}
                </span>
                <span> Royale Points</span>
              </div>
            </div>
            <div className="profile-details__buy-troutcoin-container pt-2">
              <button
                type="button"
                className="btn btn-success"
                onClick={() =>
                  dispatch(
                    modalActions.show(
                      <CommerceContainer username={userData?.name} />
                    )
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <span className="mr-2">Add Troutcoins </span>
                  <FeatherIcon icon="shopping-bag" size={18} />
                </div>
              </button>
            </div>
          </ContentBlock>
          <ContentBlock>
            <StreamerInfo streamerId={userData?.id} />
          </ContentBlock>
        </div>
      </div>
      <SkinSection
        skins={skins}
        skinsOwned={userData?.skinsOwned || []}
        userTroutcoins={userData?.troutCoins || 0}
        skinEquipped={userData?.skinEquipped}
        isLoadingSkins={isLoadingSkins}
      />
    </div>
  );
};

export default ProfileDetails;
