import { Unity, useUnityContext } from 'react-unity-webgl';
import { CF_URL } from '../../utilities/constants';
import Loading from '../../components/Loading';
import './WebBuild.css';

/**
 * Web Build page has the Unity WebGL build on it.
 * TODO: add unload on page change.
 *
 * @returns The WebGL Build for RNG Royale embedded in a web page.
 */
const WebBuild = () => {
  const buildUrl = `${CF_URL}/web-build/RNGRoyaleWebBuild/Build`;
  const buildNamespace = 'Build';
  const loaderUrl = `${buildUrl}/${buildNamespace}.loader.js`;

  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } =
    useUnityContext({
      loaderUrl,
      dataUrl: `${buildUrl}/${buildNamespace}.data.gz`,
      frameworkUrl: `${buildUrl}/${buildNamespace}.framework.js`,
      codeUrl: `${buildUrl}/${buildNamespace}.wasm.gz`,
      productName: 'RNG Royale',
      productVersion: '1.0.2',
      companyName: 'Lou Bro Studios',
    });
  const mobileMessage = 'Mobile devices are not supported :(';

  // hide for mobile devices and show a message
  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    return <div>{mobileMessage}</div>;
  }
  const loadingMessage = `Loading RNG Royale Web Build ${Math.round(
    loadingProgression * 100
  )}%...`;

  return (
    <div className="web-build-page">
      {!isLoaded && (
        <div className="unity-loading-container">
          <Loading message={loadingMessage} />
          <div className="loading-progression-container">
            <div
              className="loading-progression"
              style={{ width: `${Math.round(loadingProgression * 100)}%` }}
            />
          </div>
        </div>
      )}
      <div className="webgl__container">
        <Unity
          unityProvider={unityProvider}
          style={{ height: 600, width: 800 }}
        />
      </div>
      <div className="set-fullscreen-container">
        <button
          className="button btn"
          type="button"
          onClick={() => requestFullscreen(true)}
        >
          Set Fullscreen
        </button>
      </div>
    </div>
  );
};

export default WebBuild;
