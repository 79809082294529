import HiScoreEntry from '../HiScoreEntry';

const PlayersRankedByPoints = ({ players }) => {
  const playersRanked = [...players].sort((a, b) =>
    a.royalePoints > b.royalePoints ? -1 : 1
  );
  return (
    <div className="hiscores-container">
      <h1>Points Leaderboard</h1>
      <div className="hiscore-entries-table">
        <div className="hiscore-entry__head">
          <div className="hiscore-entry__th">Rank</div>
          <div className="hiscore-entry__th">Name</div>
          <div className="hiscore-entry__th">Score</div>
        </div>
      </div>
      <div className="hiscores-scroll">
        {playersRanked.map((player, index) => (
          <HiScoreEntry
            key={player.displayName}
            rank={index + 1}
            displayName={player.displayName}
            score={player.royalePoints}
          />
        ))}
      </div>
    </div>
  );
};

export default PlayersRankedByPoints;
