import { useAuth0 } from '@auth0/auth0-react';
import FeatherIcon from 'feather-icons-react';
import { Parallax, useParallax } from 'react-scroll-parallax';
import AuthView from './AuthView';
import UnAuthView from './UnAuthView';
import Sword from '../../components/Svg/Sword';
import ScrollSection from './ScrollSection';
import TwinSwordScroller from './TwinSwordScroller';
import Logo from '../../components/Logo';
import ContentBlock from '../../components/ContentBlock';
import './Home.css';

/**
 * Landing page home page here we start.
 * This route is special because it's index /
 * It doesnt have HelmetForRoute and just uses index.html instead.
 *
 * @returns Home page landing
 */
const HomeContent = () => {
  const { isAuthenticated } = useAuth0();
  const swordParallax = useParallax({
    translateX: [-30, 0],
    rotate: [0, 50],
  });
  const spinX2 = useParallax({
    scale: [0.5, 2, 'easeInQuad'],
  });
  const spinY = useParallax({
    rotateY: [0, 360],
  });

  return (
    <>
      <div className="content--home__logo-container">
        <Logo />
      </div>
      <div className="content--home__content-container">
        {isAuthenticated ? <AuthView /> : <UnAuthView />}
        <ScrollSection className="mb-4 pt-5">
          <Parallax
            translateX={[-20, 0]}
            speed={0.75}
            startScroll={-10}
            endScroll={300}
          >
            <ContentBlock className="scroll-section__content pb-5 pt-5">
              <h3>Stream RNG Royale</h3>
              <Parallax scale={[1, 0.4, 'easeInQuad']}>
                <FeatherIcon icon="headphones" size={36} />
              </Parallax>
              <p>
                Host interactive giveaways and games of chance! Get your viewers
                involved and start a hype train like no other.
              </p>
              <p>
                Choose from a variety of maps. Run test games to find your
                favorites.
              </p>
              <p>
                Invite your viewers to type <code>!join</code> into your Twitch
                chat in order to join a game.
              </p>
              <p>
                Start the battle royale by manually clicking the start button.
                Or set a timer to automatically start games one after the other.
              </p>
              <p>
                Wanting to continuously run games but you don&apos;t want to do
                it manually? No problem, you can set games on autoplay so that
                your chat can keep going.
              </p>
            </ContentBlock>
          </Parallax>
        </ScrollSection>
        <TwinSwordScroller />
        <ScrollSection className="mb-5">
          <ContentBlock className="scroll-section__content pb-5 pt-5">
            <h3>Play RNG Royale</h3>
            <Parallax scale={[1, 0.4, 'easeInQuad']}>
              <FeatherIcon icon="monitor" size={36} />
            </Parallax>
            <p>
              Join a game simply by typing <code>!join</code> in the chat.
            </p>
            <p>
              You can trigger events in the game via Twitch interactions like
              gifting subs.
            </p>
            <p>
              Sign in with Twitch to claim your place in the hi-scores and your
              free Trout skin! Logging in also gives you the ability to claim
              any winnings from RNG Royale games you participate in.
            </p>
            <p>
              You can also win trout coins and skins if you are lucky enough to
              win a royale.
            </p>
          </ContentBlock>
        </ScrollSection>
        <ScrollSection className="scroll-section--icon mb-3 scroll-section--reverse">
          <div ref={spinY.ref} className="mb-4">
            <FeatherIcon icon="shield" size={64} color="black" fill="black" />
          </div>
          <div ref={swordParallax.ref} className="sword-container">
            <Sword width="96px" height="64px" />
          </div>
        </ScrollSection>
        <ScrollSection>
          <ContentBlock className="scroll-section__content pb-5 pt-5 mb-3">
            <h3>Watch RNG Royale</h3>
            <Parallax scale={[1, 0.4, 'easeInQuad']}>
              <FeatherIcon icon="tv" size={36} />
            </Parallax>
            <p>
              Find games on Twitch now. Watch the cowboy shrimp slap around that
              trout.
            </p>
            <div>
              <a
                className="btn btn-lg btn-success d-inline-flex align-items-center"
                href="https://www.twitch.tv/directory/game/RNG%20Royale"
                title="Find a game"
                target="_blank"
                rel="noopener noreferrer"
              >
                Find a game on Twitch
              </a>
            </div>
          </ContentBlock>
        </ScrollSection>
        <ScrollSection className="scroll-section--icon mb-5 scroll-section--center">
          <div ref={spinX2.ref} className="scroll-section--icon__emoji">
            ⚔️
          </div>
        </ScrollSection>
      </div>
    </>
  );
};

export default HomeContent;
