import BuyableCard from '../../../components/BuyableCard/BuyableCard';

const Skin = ({
  skin,
  canBuySkin,
  doesOwnSkin,
  isLoading,
  isEquipped,
  onButtonClick,
}) => {
  const canEquipSkin = doesOwnSkin && !isEquipped;
  const cantAfford = !canBuySkin && !doesOwnSkin;
  const cantClick = isLoading || isEquipped || (!canBuySkin && !doesOwnSkin);
  const getButtonMessage = () => {
    if (cantAfford) {
      return 'Need more coins';
    }
    if (isEquipped) {
      return 'Equipped';
    }
    if (canEquipSkin) {
      return 'Equip';
    }
    if (canBuySkin) {
      return 'Buy skin';
    }
    return 'Need more coins';
  };

  const buttonMessage = getButtonMessage();

  let assetFile = '';
  const filePrefix = 'https://d34dx5b6qkv1nr.cloudfront.net/models';
  if (skin.id) {
    assetFile = `${filePrefix}/${skin.id}/${skin.id}.fbx`;
  }

  return (
    <div className={`skin ${doesOwnSkin ? 'skin--owned' : ''}`}>
      <BuyableCard
        name={skin.displayName}
        assetFile={assetFile}
        description={skin.description}
        price={skin.price}
        imageSrc={skin.imageUrl}
        buttonMessage={buttonMessage}
        isDisabled={cantClick}
        isLoading={isLoading}
        onClick={onButtonClick}
        cantAffordAndDoesntOwn={cantAfford}
        ownsItem={doesOwnSkin}
      />
    </div>
  );
};

export default Skin;
