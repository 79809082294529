import Grid from '../../Grid/Grid';
import GridItem from '../../Grid/GridItem';
import SkeletonBlock from '../SkeletonBlock';
import './SkinsSkeleton.css';

const blockNumbers = [...Array(8).keys()].map((x) => x + 1);
const SkinsSkeleton = ({ message }) => (
  <div className="skins-skeleton">
    <div className="skeleton__message">{message}</div>
    <Grid>
      {blockNumbers?.map((blockNumber) => (
        <GridItem key={`_${blockNumber}`}>
          <SkeletonBlock />
        </GridItem>
      ))}
    </Grid>
  </div>
);

export default SkinsSkeleton;
