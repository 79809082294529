import SkeletonBlock from '../Skeleton/SkeletonBlock';
import './ContentBlock.css';

/**
 * This is a content block that can have text or any content with a slightly transparent background
 * @param {HTMLElement | String} children - inner children
 * @param {string} className - optional classNames to add (esp useful for utility classes)
 * @param {boolean} isLoading - optional loading state for showing Skeleton
 * @param {{ width: string, height: string }} loaderSize - optional loader width and height
 * @returns ContentBlock component
 */
const ContentBlock = ({
  children,
  className = '',
  isLoading = false,
  loaderSize = {
    width: '222px',
    height: '222px',
  },
}) => (
  <div className={`content-block ${className}`}>
    {!isLoading ? (
      children
    ) : (
      <SkeletonBlock width={loaderSize.width} height={loaderSize.height} />
    )}
  </div>
);

export default ContentBlock;
