import { Helmet } from 'react-helmet';

const HelmetForRoute = ({ route }) => {
  const siteTitle = 'RNG Royale!';
  const pageTitle = route.title ? `${route.title} | ${siteTitle}` : siteTitle;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
      {route.description && (
        <meta name="description" content={route.description} />
      )}
    </Helmet>
  );
};

export default HelmetForRoute;
