import { API_BASE_URL } from '../ducks/initializers';

// https://developer.paypal.com/sdk/js/reference/#createorder
// https://developer.paypal.com/docs/api/orders/v2/#orders_create
const createOrderFetchOptions = (params) => {
  const { id, name, locale } = params;
  // they cant make more than 1 transaction per millisecond
  // we could even round it to seconds or minutes.
  const referenceId = `${id}_${Date.now()}`;
  const paypalRequestId = `${Date.now()}_${id}`;
  return {
    method: 'POST',
    body: JSON.stringify({
      id,
      name,
      locale,
      operation: 'create-paypal-order',
      referenceId,
    }),
    headers: {
      paypalRequestId,
    },
  };
};

// api call to create paypal order
// eslint-disable-next-line import/prefer-default-export
export const createTroutcoinOrder = async (
  params,
  awaitedAccessTokenFunction
) => {
  const fetchOptions = createOrderFetchOptions(params);

  try {
    const token = await awaitedAccessTokenFunction({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    const fetchParams = {
      ...fetchOptions,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...fetchOptions.headers,
      },
    };

    const endpoint = `users/create-troutcoin-transaction/${params.id}`;

    const response = await fetch(`${API_BASE_URL}/${endpoint}`, fetchParams);
    const textResponse = await response.text();
    if (response?.status === 200) {
      // do json parse of the text response instead of the json() helper
      const jsonResponse = textResponse ? JSON.parse(textResponse) : {};
      return jsonResponse.id;
    }
    return null;
  } catch (e) {
    console.error(`error creating troutcain transaction`, e);
    return e;
  }
};
