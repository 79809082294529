import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { actions as addTroutCoinUserActions } from '../../ducks/user/addTroutCoin';
import { createTroutcoinOrder } from '../../utilities/createTroutcoinTransaction';
import CommerceButton from '../CommerceButton';
import { getAuthUserId } from '../../utilities/authUtils';
import { DISCORD_URL } from '../../utilities/constants';
import { getLocale } from '../../utilities/getLocale';

/**
 * The container for a button to open a modal to PayPal and Coinbase Commerce
 * TODO: add warning if user tries to exit before transaction is complete
 *
 * @returns the Commerce container
 */
const CommerceContainer = ({ username = '' }) => {
  const { user, name, getAccessTokenSilently } = useAuth0();
  const locale = getLocale();
  const dispatch = useDispatch();
  const userId = getAuthUserId(user);
  const [isCopied, setIsCopied] = useState(false);
  const copyButtonColor = isCopied ? 'success' : 'primary';
  const ClipboardIcon = () => {
    const icon = isCopied ? 'check' : 'clipboard';
    return (
      <div className="copy-to-clipboard-button__icon">
        {' '}
        <FeatherIcon icon={icon} size={18} />
      </div>
    );
  };

  const createOrder = () =>
    // todo: consider putting this in a useMemo
    // this is a race condition and has issues
    createTroutcoinOrder({ id: userId, name, locale }, getAccessTokenSilently);
  const isCopiedText = isCopied ? 'Copied' : 'Copy';
  const copyText = `${isCopiedText} ${username} to Clipboard`;

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };

  return (
    <div className="commerce-content padding-l pb-2">
      <h3>Purchase troutcoins</h3>
      <p>You can pay via PayPal or via Coinbase.</p>
      <div>
        <h4>PayPal</h4>
        <p className="alert alert-info">
          Please{' '}
          <a
            href={DISCORD_URL}
            title="Join the Discord"
            target="_blank"
            rel="noopener noreferrer"
          >
            join our Discord channel
          </a>{' '}
          to provide feedback and bug reports.
        </p>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={(data, actions) =>
            actions.order.capture().then((details) => {
              // only process if order was completed
              if (
                details.intent === 'CAPTURE' &&
                details.status === 'COMPLETED'
              ) {
                ReactGA.event({
                  category: 'purchase',
                  action: 'paypal-purchase',
                  label: 'PayPal',
                  value: 1,
                  nonInteraction: false,
                });
                // dispatch add trout coin action for this user
                // TODO: add more data to message data to confirm it's not the same purchase
                dispatch(
                  addTroutCoinUserActions.load(
                    {
                      id: userId,
                      name,
                      messageData: { type: 'PAYPAL', ...details },
                    },
                    getAccessTokenSilently
                  )
                );
              }
            })
          }
        />
      </div>
      <h4>Coinbase Commerce</h4>
      <p>
        Use the button below to be taken to Coinbase Commerce to complete your
        transaction. Please leave the browser window open until the transaction
        completes. For the name field, enter your Twitch username exactly as it
        is spelled. Your email address is not required, it is used by Coinbase
        to update you on the transaction status.
      </p>
      <p className="alert alert-warning">
        <span>
          WARNING: You will have to disable adblocker for coins to be added to
          your account.
        </span>
      </p>
      <p>
        For your convenience, and to prevent any issues, use the button below to
        copy your username so you can paste it into the Coinbase input:
      </p>
      <div className="mb-3">
        <CopyToClipboard
          text={username}
          onCopy={handleCopy}
          className={`btn btn-primary btn-${copyButtonColor} mr-2 transition-all`}
        >
          <div>
            <div className="copy-to-clipboard-button">
              <span className="copy-to-clipboard-button__text">{copyText}</span>
              <ClipboardIcon />
            </div>
          </div>
        </CopyToClipboard>
      </div>
      <p>
        Now that you have your username copied, use the button below to open
        Coinbase Commerce:
      </p>
      <CommerceButton messageOverride="Buy with Coinbase" className="mb-3" />
    </div>
  );
};

export default CommerceContainer;
