const HiScoreEntry = ({ rank, displayName, score }) => (
  <div className="hiscore-entry">
    <div className="hiscore-entry__rank hiscore-entry__cell">{rank}</div>
    <div className="hiscore-entry__name hiscore-entry__cell">
      <strong>{displayName}</strong>
    </div>
    <div className="hiscore-entry__score hiscore-entry__cell">{score}</div>
  </div>
);

export default HiScoreEntry;
