import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ReactGA from 'react-ga4';
import ContentBlock from '../../components/ContentBlock';

const windowsGameDownload =
  'https://d34dx5b6qkv1nr.cloudfront.net/RNG_ROYALE_WINDOWS_64_v1.0.3.zip';
const macGameDownload =
  'https://d34dx5b6qkv1nr.cloudfront.net/RNG_ROYALE_MACOSX_64_v1.0.3.app.zip';

const logDownloadClick = (downloadType = '') => {
  ReactGA.event({
    category: 'game-download',
    action: downloadType,
    label: 'FileDownload',
    value: 1,
    nonInteraction: false,
  });
};

const AuthView = () => (
  <div className="home home--auth">
    <div className="home__content-blocks">
      <ContentBlock className="margin-bottom">
        <h1 className="display-none">RNG Royale</h1>
        <div className="d-flex justify-content-center margin-bottom">
          <a
            href={windowsGameDownload}
            target="_blank"
            className="btn btn-primary mr-1"
            rel="noopener noreferrer"
            title="Download RNG Royale for Windows"
            onClick={(e) => logDownloadClick('windows')}
          >
            Download for Windows
          </a>
          <a
            href={macGameDownload}
            target="_blank"
            className="btn btn-primary"
            rel="noopener noreferrer"
            title="Download RNG Royale for Mac"
            onClick={(e) => logDownloadClick('mac')}
          >
            Download for Mac
          </a>
        </div>
        <p>
          Access your&nbsp;
          <Link to="/profile" className="link">
            profile page
          </Link>
          &nbsp;to purchase &amp; equip skins for your character.
        </p>
      </ContentBlock>
      <ContentBlock>
        <h3>How to setup</h3>
        <p>
          After downloading the game, tell your Twitch chat to join using the{' '}
          <code>!join</code> command.
        </p>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/JeFPokfHnAc"
            title="How to set-up RNG Royale | YouTube video player"
            allowFullScreen
          />
        </div>
      </ContentBlock>
      <ContentBlock>
        <div>
          <a
            className="btn btn-lg btn-success d-flex align-items-center"
            href="https://www.twitch.tv/directory/game/RNG%20Royale"
            title="Find a game"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="find-a-game-text mr-1 uppercase">
              Find a game on Twitch now
            </span>
            <FeatherIcon icon="play" size={32} />
          </a>
        </div>
      </ContentBlock>
    </div>
  </div>
);

export default AuthView;
