import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as getUserActions,
  getUserGetState,
} from '../../ducks/user/get';
import { getAuthUserId } from '../../utilities/authUtils';
import ProfileDetails from './ProfileDetails';
import TwitchLogo from '../../components/Svg/TwitchLogo';
import ContentBlock from '../../components/ContentBlock';
import useTwitchUsernameFromAuth0 from '../../hooks/useTwitchUsernameFromAuth0';
import HelmetForRoute from '../../components/HelmetForRoute';
import './Profile.css';

/**
 * the Profile page to show data about the currently signed-in user
 *
 * @returns the user profile page
 */
const Profile = () => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const { picture } = user;
  const twitchUsername = useTwitchUsernameFromAuth0();
  const currentUser = useSelector((state) => getUserGetState(state));
  const isLoadingUser = currentUser?.isLoading;

  useEffect(() => {
    const userId = getAuthUserId(user);
    // on page load, get user by id from api by dispatching load action
    dispatch(getUserActions.load({ id: userId }, getAccessTokenSilently));
  }, [dispatch, getAccessTokenSilently, user]);

  const profileRouteData = {
    title: 'Profile',
    description:
      'Your RNG Royale profile. You can customize your character or buy new skins using Troutcoin. If you need, you can purchase more troutcoin using the Coinbase Commerce application in this page.',
  };

  return (
    <div className="profile">
      <HelmetForRoute route={profileRouteData} />
      <ContentBlock>
        <h1 className="profile__title">
          <div className="profile__twitch-avatar">
            <img
              className="profile__twitch-avatar__image"
              src={picture}
              alt={`${twitchUsername}'s Twitch avatar`}
              title={`${twitchUsername} on Twitch.tv`}
            />
          </div>
          <span className="profile__twitch-logo">
            <TwitchLogo />
          </span>
          <span className="profile__twitch-name">{twitchUsername}</span>
        </h1>
        {isLoadingUser && <p>Retrieving user info...</p>}
      </ContentBlock>
      <ProfileDetails
        isLoadingUser={isLoadingUser}
        userData={currentUser?.data}
      />
    </div>
  );
};

export default Profile;
