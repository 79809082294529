import ContentBlock from '../../components/ContentBlock';

const Roadmap = () => {
  const notFoundText = `That page wasn't found. Sorry friend.`;
  const shrugMan = '¯\\_(ツ)_/¯';

  return (
    <div className="not-found">
      <ContentBlock>
        <h1>Not Found</h1>
        <h2 className="not-found__shrug">{shrugMan}</h2>
        <div className="not-found__contents">
          <p>{notFoundText}</p>
        </div>
      </ContentBlock>
    </div>
  );
};

export default Roadmap;
