import useVisibility from '../../hooks/useVisibility';

const ScrollSection = ({ children, className }) => {
  const [isInView, inViewRef] = useVisibility();
  const visibleClassName = isInView ? ' scroll-section--visible' : '';
  return (
    <div
      className={`scroll-section ${className}${visibleClassName}`}
      ref={inViewRef}
    >
      {children}
    </div>
  );
};

export default ScrollSection;
