/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import {
  Center,
  OrbitControls,
  PerspectiveCamera,
  useGLTF,
} from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const COIN_ASSET =
  'https://d34dx5b6qkv1nr.cloudfront.net/models/troutcoin3dEmbedded.gltf';

function KeyLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={5}
      height={3}
      color={color}
      intensity={brightness}
      position={[-2, -4, 6]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
}

const Model = () => {
  const { scene } = useLoader(GLTFLoader, COIN_ASSET);

  useEffect(() => {
    scene.rotation.x = 1;
    scene.rotation.z = 1.7;
    scene.position.x = 0;
  }, [scene]);
  useFrame((_, delta) => {
    scene.rotation.y -= 1 * delta;
  });
  return (
    <>
      <primitive object={scene} />
    </>
  );
};

const TroutCoin3d = () => (
  <Canvas>
    <Suspense fallback={null}>
      <Center>
        <Model />
      </Center>
      <ambientLight />
      <KeyLight brightness={50} />
      <PerspectiveCamera makeDefault position={[0, 0, 4]} fov={50} zoom={1.2} />
      <OrbitControls />
    </Suspense>
  </Canvas>
);
useGLTF.preload(COIN_ASSET);

export default TroutCoin3d;
