// lol spaces in the url
const logoFileName = encodeURI('RNG Royale Logo (1920x1080 100ppi)');
const logoSrc = `https://d34dx5b6qkv1nr.cloudfront.net/${logoFileName}.png`;

const Logo = () => (
  <div className="logo-container">
    <img src={logoSrc} className="logo" alt="RNG Royale logo" />
  </div>
);

export default Logo;
