/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Bounds, OrbitControls, useFBX } from '@react-three/drei';

const Model = ({ skinName }) => {
  const fbx = useFBX(skinName);

  useEffect(() => {
    fbx.position.y = -50;
  }, [fbx]);
  useFrame((_, delta) => {
    fbx.rotation.y += 0.5 * delta;
  });
  return (
    <>
      <primitive object={fbx} />
    </>
  );
};

function KeyLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={5}
      height={3}
      color={color}
      intensity={brightness}
      position={[-2, 7, 5]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
}

const Skin3d = ({ skinName }) => (
  <Canvas camera={{ position: [0, 0, 100], fov: 50 }}>
    <Suspense fallback={null}>
      <Bounds fit clip observe>
        <Model skinName={skinName} />
      </Bounds>
      <ambientLight />
      <KeyLight brightness={150} color="#ffc9f9" />
      <OrbitControls makeDefault enableZoom={false} />
    </Suspense>
  </Canvas>
);

export default Skin3d;
