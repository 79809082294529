import { useAuth0 } from '@auth0/auth0-react';
import FeatherIcon from 'feather-icons-react';
import ContentBlock from '../../components/ContentBlock';

const UnAuthView = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="home home--unauth">
      <ContentBlock className="margin-bottom home__content-blocks--unauth">
        <div className="home__content-block--unauth">
          <h1 className="caps">RNG Royale</h1>
          <div className="margin-bottom">
            <button
              className="btn btn-primary btn-block button--landing-sign-in"
              onClick={loginWithRedirect}
              type="button"
            >
              Sign in with Twitch
            </button>
          </div>
          <p>
            Sign in to Download, Play, and visit your profile to Purchase and
            Equip skins for your character
          </p>
          <div>
            <a
              className="btn btn-lg btn-success d-inline-flex align-items-center"
              href="https://www.twitch.tv/directory/game/RNG%20Royale"
              title="Find a game"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="find-a-game-text mr-1 uppercase">
                Find a game on Twitch now
              </span>
              <FeatherIcon icon="play" size={32} />
            </a>
          </div>
        </div>
        <div className="home__content-block--unauth">
          <div>
            <p>
              Welcome to RNG Royale, where every viewer has a shot at victory.
            </p>
            <p>
              Engage your audience like never before as they dive into
              interactive battles right from your Twitch stream.
            </p>
          </div>
          <h2>What is RNG Royale?</h2>
          <div>
            <ul className="text-left list-group">
              <li className="list-group-item list-group-item-info">
                RNG Royale is a game made to be run by streamers on twitch.tv
              </li>
              <li className="list-group-item list-group-item-info">
                Viewers use the command <code>!join</code> to participate.
              </li>
              <li className="list-group-item list-group-item-info">
                Participants fight to the death until only one is alive.
              </li>
              <li className="list-group-item list-group-item-info">
                Random events can be triggered through subscriptions.
              </li>
              <li className="list-group-item list-group-item-info">
                Winners get a chance to win skins, coins, and points.
              </li>
            </ul>
          </div>
        </div>
      </ContentBlock>
    </div>
  );
};

export default UnAuthView;
