import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../auth/ProtectedRoute';
import Home from '../../views/Home';
import Profile from '../../views/Profile';
import HiScores from '../../views/HiScores';
import Roadmap from '../../views/Roadmap';
import NotFound from '../../views/NotFound';
import WebBuild from '../../views/WebBuild';
import './View.css';

/**
 * The generic View component will render a specific view per route.
 * @returns a View containing a component
 */
const View = () => (
  <div className="view">
    <Switch>
      <Route path="/" exact component={Home} />
      <ProtectedRoute path="/profile" component={Profile} />
      <ProtectedRoute path="/hiscores" component={HiScores} />
      <ProtectedRoute path="/roadmap" component={Roadmap} />
      <ProtectedRoute path="/web-build" component={WebBuild} />
      <Route path="/" component={NotFound} />
    </Switch>
  </div>
);

export default View;
