import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const StreamerInfo = ({ streamerId }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyButtonColor = isCopied ? 'success' : 'primary';
  const sendStopStreamingRequest = () => {};
  const ClipboardIcon = () => {
    const icon = isCopied ? 'check' : 'clipboard';
    return (
      <div className="copy-to-clipboard-button__icon">
        {' '}
        <FeatherIcon icon={icon} size={18} />
      </div>
    );
  };
  return (
    <div className="streamer-info">
      <p>
        To stream RNG Royale, copy your ID below and paste it into the RNG
        Royale game window. <strong>Do not </strong>share it.
      </p>
      <div className="streamer-id-container">
        <CopyToClipboard
          text={streamerId}
          onCopy={() => setIsCopied(true)}
          className={`btn btn-primary btn-${copyButtonColor} mr-2`}
        >
          <div>
            <div className="copy-to-clipboard-button">
              <span className="copy-to-clipboard-button__text">
                Copy to clipboard
              </span>
              <ClipboardIcon />
            </div>
          </div>
        </CopyToClipboard>
      </div>
      <div className="pt-2 d-none">
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => sendStopStreamingRequest()}
        >
          PANIC: Send End Stream Request
        </button>
      </div>
    </div>
  );
};

export default StreamerInfo;
