const Sword = ({
  width = '128px',
  height = '97px',
  color = 'currentColor',
}) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className="sword"
    viewBox="0 0 1280 973"
    preserveAspectRatio="xMidYMid meet"
  >
    <defs>
      <linearGradient id="sword-gradient">
        <stop offset="0" stopColor="#000">
          <animate
            dur="2s"
            attributeName="offset"
            fill="freeze"
            from="0"
            to="1"
          />
        </stop>
        <stop offset="0" stopColor="#fff">
          <animate
            dur="2s"
            attributeName="offset"
            fill="freeze"
            from="0"
            to="1"
          />
        </stop>
      </linearGradient>
    </defs>
    <g transform="translate(0,973) scale(0.100000,-0.100000)">
      <path
        fill="url(#sword-gradient)"
        d="M12425 9458 c-82 -27 -440 -145 -795 -262 l-645 -212 -335 -244
c-184 -134 -1703 -1241 -3375 -2460 -1672 -1219 -3075 -2241 -3117 -2270 l-77
-53 -73 2 c-40 1 -109 9 -153 18 -44 8 -90 16 -103 17 -14 2 -74 48 -140 109
l-117 106 -179 318 c-99 175 -229 405 -289 512 l-110 193 -17 -28 c-224 -373
-380 -645 -374 -655 4 -8 3 -9 -4 -5 -16 10 -39 -30 -56 -99 -52 -198 13 -349
415 -957 117 -178 161 -273 167 -365 6 -101 -19 -183 -58 -183 -81 0 -169 -61
-194 -134 -13 -38 -16 -40 -60 -42 -77 -5 -126 -39 -161 -111 l-30 -61 -47 0
c-52 -1 -92 -18 -124 -54 -26 -28 -54 -81 -54 -103 0 -11 -12 -15 -50 -15 -28
0 -63 -6 -78 -14 -41 -21 -82 -69 -97 -112 -13 -38 -15 -39 -67 -44 -91 -10
-146 -52 -170 -131 -11 -38 -12 -39 -57 -39 -29 0 -61 -9 -86 -23 -44 -25 -94
-91 -95 -124 0 -20 -5 -23 -45 -23 -25 0 -60 -6 -78 -14 -41 -17 -91 -74 -107
-120 -11 -34 -14 -36 -54 -36 -82 0 -168 -67 -183 -142 -5 -25 -10 -28 -47
-28 -81 -1 -156 -53 -181 -127 -12 -36 -16 -38 -62 -44 -110 -14 -174 -82
-189 -200 -4 -31 -16 -67 -29 -84 -56 -75 -69 -116 -69 -205 0 -75 4 -92 31
-145 78 -152 231 -216 391 -166 73 22 195 89 187 101 -4 6 2 7 11 3 13 -5 15
-3 9 7 -5 8 -4 11 2 7 13 -8 229 148 225 163 -1 6 2 9 8 5 14 -9 42 14 33 28
-3 6 -1 7 6 3 15 -10 202 124 194 138 -3 6 -2 8 2 3 8 -7 36 8 73 38 9 8 58
44 107 80 50 36 89 71 87 76 -1 6 2 8 7 5 5 -3 60 31 123 75 110 80 383 280
403 296 6 5 38 28 72 52 35 24 60 48 56 54 -3 5 -2 7 4 4 9 -6 70 36 272 183
33 25 116 85 182 133 178 130 262 185 337 220 59 28 77 31 157 32 80 0 96 -3
148 -29 65 -34 148 -111 253 -236 38 -47 147 -177 240 -290 256 -307 348 -386
493 -420 93 -22 247 7 306 58 37 31 69 83 56 91 -10 6 156 49 422 109 43 9 79
20 80 23 1 3 -158 179 -354 390 -195 211 -374 404 -396 429 -35 39 -126 219
-104 205 5 -2 14 44 20 103 26 229 60 311 186 438 77 78 127 118 404 319 68
50 194 141 280 204 86 63 206 151 266 195 61 44 162 118 227 165 64 47 126 93
138 101 12 8 75 53 139 101 167 123 315 230 486 355 83 59 224 163 315 229 91
66 233 170 315 230 83 59 202 146 265 193 63 46 124 90 135 98 11 8 63 45 115
84 52 39 104 76 115 84 11 8 74 54 140 102 66 48 167 122 225 164 58 42 175
128 261 191 86 63 194 141 240 175 46 33 145 105 219 159 188 137 490 357 542
395 24 17 90 66 148 109 58 42 173 126 255 186 83 59 202 147 267 194 64 47
126 93 138 101 32 22 169 122 295 215 61 45 144 105 185 135 129 91 262 201
351 290 47 47 119 109 160 139 82 60 32 -6 709 931 363 502 355 490 343 489
-4 0 -75 -23 -158 -51z"
      />
    </g>
  </svg>
);

export default Sword;
