import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { CF_URL } from '../../utilities/constants';
import useTimeout from '../../hooks/useTimeout';
import HomeContent from './HomeContent';
import { selectVideoState, setEnded, setHidden } from '../../ducks/videoState';
import './Home.css';

/**
 * Landing page home page here we start.
 * This route is special because it's index /
 * It doesnt have HelmetForRoute and just uses index.html instead.
 *
 * @returns Home page landing
 */
const Home = () => {
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const { hidden: videoHidden, ended: videoEnded } = useSelector((state) =>
    selectVideoState(state)
  );

  const [delayedSetVideoHidden] = useTimeout(() => {
    dispatch(setHidden(true));
  }, 750);

  const handleVideoEnd = () => {
    dispatch(setEnded(true));
    delayedSetVideoHidden();
  };

  const showVideo = !videoHidden && !isAuthenticated;
  const showHomeContent = videoEnded || isAuthenticated;
  return (
    <section
      className={`content content--home ${
        isAuthenticated ? 'content--home--auth' : 'content--home--unauth'
      }`}
    >
      {showVideo && (
        <div className="content--home__bg-video-container">
          <video
            playsInline
            autoPlay
            muted
            onEnded={handleVideoEnd}
            className={`content--home__bg-video${
              videoEnded ? ' content--home__bg-video--ended' : ''
            }`}
          >
            <source
              src={`${CF_URL}/videos/RNG-Royale-Splash.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      )}
      {showHomeContent && <HomeContent />}
    </section>
  );
};

export default Home;
