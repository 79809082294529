import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import user from './user';
import skins from './skins';
import hiscores from './hiscores';
import notifications from './notifications';
import modal from './modal';
import videoStateReducer from './videoState';

const rootReducer = combineReducers({
  user,
  skins,
  hiscores,
  notifications,
  modal,
  videoState: videoStateReducer,
});
const middlewares = [];
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}
export default configureStore({
  reducer: rootReducer,
  middleWare: (getDefaultMiddleware) =>
    getDefaultMiddleware.concat(middlewares),
});
