import { CoinbaseCommerceButton } from '@iofate/react-coinbase-commerce';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import { actions as addTroutCoinUserActions } from '../../ducks/user/addTroutCoin';
import { getAuthUserId } from '../../utilities/authUtils';
import { actions as notificationActions } from '../../ducks/notifications';
import '@iofate/react-coinbase-commerce/dist/esm/index.css';

const checkoutId = 'e2a1bbe6-5aa7-4ea0-9e5c-abbc5111615a';

/**
 * The CommerceButton for interacting with coinbase.
 * The onChargeSuccess will call the add trout coins API.
 * Server can handle messageData which we pass back to it, and fire the redux action.
 * TODO: add security around charge handler API actions, use webhooks on backend.
 * right now everything relies on that onChargeSuccess function
 * @returns Coinbase Commerce Button
 */
const CommerceButton = ({ messageOverride = '', className = '' }) => {
  const { user, name, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const userId = getAuthUserId(user);

  return (
    <div>
      <CoinbaseCommerceButton
        className={`btn btn-primary ${className}`}
        checkoutId={checkoutId}
        onChargeSuccess={(messageData) => {
          ReactGA.event({
            category: 'purchase',
            action: 'coinbase-purchase',
            label: 'CoinBase',
            value: 1,
            nonInteraction: false,
          });
          const messageDataCoinbase = { ...messageData, type: 'COINBASE' };
          dispatch(
            addTroutCoinUserActions.load(
              { id: userId, name, messageData: messageDataCoinbase },
              getAccessTokenSilently
            )
          );
        }}
        onChargeFailure={(messageData) => {
          console.error('Charge failed!, data:', messageData);
          // TODO: call transactions api to set transaction to failed
          dispatch(
            notificationActions.error({
              message: 'Oh no... the charge failed. Please try again.',
              messageData,
            })
          );
        }}
        onPaymentDetected={(messageData) => {
          console.log('Payment detected, data:', messageData);
          // TODO: call transactions api to set transaction to pending
        }}
      >
        {messageOverride || 'Buy Troutcoins'}
      </CoinbaseCommerceButton>
    </div>
  );
};

export default CommerceButton;
