import { Parallax } from 'react-scroll-parallax';
import Sword from '../../components/Svg/Sword';

const TwinSwordScroller = () => (
  <div>
    <Parallax
      translateX={[100, -200]}
      translateY={[0, 200]}
      className="d-inline-block"
      startScroll={100}
    >
      <div className="cross-sword cross-sword-1">
        <Sword width="96px" height="64px" />
      </div>
    </Parallax>
    <Parallax
      translateX={[-100, 200]}
      translateY={[0, 200]}
      className="d-inline-block"
      startScroll={100}
    >
      <div className="cross-sword cross-sword-2">
        <Sword width="96px" height="64px" />
      </div>
    </Parallax>
  </div>
);

export default TwinSwordScroller;
