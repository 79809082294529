import ContentBlock from '../../components/ContentBlock';
import './Roadmap.css';

const Roadmap = () => {
  const currentVersion = '1.0.1';
  const currentVersionText = `Current version: ${currentVersion}`;

  return (
    <div className="roadmap">
      <ContentBlock>
        <h1>Roadmap</h1>
        <div className="roadmap__contents">
          <h2>{currentVersionText}</h2>
          <div className="roadmap__section">
            <h3>
              <em>Planned for: </em>1.1.0
            </h3>
            <ul className="roadmap__list">
              <li className="roadmap__list-item">
                Ability to claim points if you won a game but did not yet
                register
              </li>
              <li className="roadmap__list-item">
                Good random events during a game, not just bad ones
              </li>
            </ul>
          </div>
          <div className="roadmap__section">
            <h3>1.0.1</h3>
            <ul className="roadmap__list">
              <li className="roadmap__list-item">
                Fix bug that was tracking wins for every game played.
              </li>
              <li className="roadmap__list-item">Fix a security issue.</li>
            </ul>
          </div>
          <div className="roadmap__section">
            <h3>1.0.0</h3>
            <ul className="roadmap__list">
              <li className="roadmap__list-item">Official release!</li>
            </ul>
          </div>
        </div>
      </ContentBlock>
    </div>
  );
};

export default Roadmap;
