import PropTypes from 'prop-types';
import { useState } from 'react';
import CommerceButton from '../CommerceButton';
import LockIcon from '../Svg/LockIcon';
import Troutcoin from '../Troutcoin';
import Skin3d from '../Skin3d';
import './BuyableCard.css';

/**
 * BuyableCard is a card with image, description, and buy/activate button.
 * It is used for any type of content which can be buyable (Skins, Animations, etc)
 * It accepts props for actions
 * @param {string} name display name title
 * @param {string | React.Component} description description area -- can be React component or string
 * @param {number} price price of the item in trout coins
 * @param {string} buttonMessage message for the button
 * @param {function} onClick action to handle button click
 * @param {boolean} isDisabled whether or not the button should be disabled
 * @param {boolean} isLoading is loading currently
 * @param {boolean} cantAffordAndDoesntOwn if they cant afford and ddont own it then show commerce button lol
 * @returns BuyableCard react component
 */
const BuyableCard = ({
  name = '',
  assetFile = '',
  price = 0,
  imageSrc = '',
  description = '',
  buttonMessage = '',
  cantAffordAndDoesntOwn = false,
  ownsItem = false,
  onClick,
  isLoading,
  isDisabled = false,
}) => {
  const [hasBeenHovered, setHasBeenHovered] = useState(false);
  return (
    <div className="buyable-card">
      <div
        className="buyable-card__image-container"
        onMouseEnter={() => setHasBeenHovered(true)}
      >
        {hasBeenHovered && !!assetFile && (
          <div className="buyable-card__hovering-container skin-3d-container">
            <Skin3d skinName={assetFile} />
          </div>
        )}

        <img
          className={`buyable-card__image ${
            ownsItem
              ? 'buyable-card__image--owned'
              : 'buyable-card__image--not-owned'
          }`}
          src={imageSrc}
          alt={name}
        />
        {!ownsItem && (
          <div className="buyable-card__image__lock">
            <LockIcon size={32} />
          </div>
        )}
      </div>
      <h5 className="buyable-card__name">{name}</h5>
      <div className="buyable-card__description">{description}</div>
      <div
        className={`buyable-card__price ${
          cantAffordAndDoesntOwn ? 'buyable-card__price--cant-afford' : ''
        } ${
          ownsItem
            ? 'buyable-card__price--owned'
            : 'buyable-card__price--not-owned'
        }`}
      >
        <div className="buyable-card__price__inner">
          <Troutcoin size={18} />
          <span className="buyable-card__price-count">{price}</span>
        </div>
        {cantAffordAndDoesntOwn && (
          <div className="cant-afford-message">Not enough coins!</div>
        )}
      </div>
      {!cantAffordAndDoesntOwn ? (
        <button
          type="button"
          disabled={isDisabled}
          onClick={!isDisabled ? onClick : () => {}}
          className="buyable-card__action"
        >
          {isLoading && (
            <span className="buyable-card__action__loading-spinner">⏳</span>
          )}
          {buttonMessage}
        </button>
      ) : (
        <CommerceButton
          className="buyable-card__action buyable-card__action--commerce"
          messageOverride={
            <div className="flex align-items-center justify-content-center flex-column">
              <Troutcoin size={18} />
              <div>Buy more Troutcoin?</div>
            </div>
          }
        />
      )}
    </div>
  );
};

BuyableCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  imageSrc: PropTypes.string,
  description: PropTypes.string,
  buttonMessage: PropTypes.string,
  cantAffordAndDoesntOwn: PropTypes.bool,
  ownsItem: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default BuyableCard;
