import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PlayersRankedByPoints from './tabs/PlayersRankedByPoints';
import PlayersRankedByWins from './tabs/PlayersRankedByWins';

/**
 * HiScoresList will have routing to decide which hi scores to show
 */
const HiScoresList = ({ players }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <PlayersRankedByPoints players={players} />
      </Route>
      <Route path={`${path}/most-wins`}>
        <PlayersRankedByWins players={players} />
      </Route>
    </Switch>
  );
};

export default HiScoresList;
