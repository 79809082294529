import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import {
  actions as listPlayersActions,
  getPlayersListState,
} from '../../ducks/hiscores/listPlayers';
import HiScoresList from './HiScoresList';
import ContentBlock from '../../components/ContentBlock';
import HelmetForRoute from '../../components/HelmetForRoute';
import Loading from '../../components/Loading';
import './HiScores.css';

/**
 * HiScores page has types of rankings
 * Most points from a game
 * Most coins from a game
 * Players with most wins
 * Players with most points
 * @returns /hiscores landing page
 */
const HiScores = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const { pathname } = location;
  const { url } = useRouteMatch();
  const {
    data: players,
    isLoading: isLoadingPlayers,
    error: errorLoading,
  } = useSelector((state) => getPlayersListState(state));

  useEffect(() => {
    // on first load, fetch games played
    if (!players) {
      dispatch(listPlayersActions.load({}, getAccessTokenSilently));
    }
  }, [dispatch, getAccessTokenSilently, players]);

  // this will have sub-routes for diff hi-scores or whatever lol
  return (
    <div className="hiscores">
      <HelmetForRoute
        route={{
          title: 'HiScores',
          description:
            'RNG Royale Leaderboard and hi scores. Who is the best troutie?',
        }}
      />
      <div className="hiscores__tabs">
        <Link
          to={url}
          className={`hiscores__tab ${
            pathname === '/hiscores' ? 'hiscores__tab--active' : ''
          }`}
        >
          Points
        </Link>
        <Link
          to={`${url}/most-wins`}
          className={`hiscores__tab ${
            pathname === '/hiscores/most-wins' ? 'hiscores__tab--active' : ''
          }`}
        >
          Wins
        </Link>
      </div>
      <ContentBlock>
        {!isLoadingPlayers && players && <HiScoresList players={players} />}
        {isLoadingPlayers && <Loading message="Loading the HiScores..." />}
        {errorLoading && `Error loading hiscores :(`}
      </ContentBlock>
    </div>
  );
};

export default HiScores;
